import { Injectable } from '@angular/core';
import { UserDtl, UsBrDtl, FirmDtl, BillCharges, CmpInfo, OptionAc, OptionEmail, DocDtl, StationDtl, VhDtl, MatDtl, DrvDtl, Acm } from './userModel'
import { AppConfig } from '../app.config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';




@Injectable({
  providedIn: 'root'
})
export class UserDtlService {

  constructor(private http: HttpClient) { }

  getUserList() {

    var sUrl = environment.HOST_URL + AppConfig.getUserList;
    return this.http.get<UserDtl[]>(sUrl);
  }

  getUserByRefNo(refNo) {
    var url = environment.HOST_URL + AppConfig.getUserByRefNo.concat('/?id=').concat(refNo);
    return this.http.get<UserDtl>(url);
  }

  updateUser(params: UserDtl) {
    return this.http.post<UserDtl>(environment.HOST_URL + AppConfig.updateUser, params);
  }

  addNewUser(params) {
    var url = environment.HOST_URL + AppConfig.addLoginUser;
    return this.http.post<UserDtl>(url, params);
  }

  deleteUser(refNo) {
    return this.http.get(environment.HOST_URL + AppConfig.deleteUser + '/?id='.concat(refNo));
  }
  GetAdminMenuList() {

    var sUrl = environment.HOST_URL + AppConfig.GetAdminMenuList;
    return this.http.get<[]>(sUrl);
  }
  getBranchData() {
    return this.http.get<[any]>(environment.HOST_URL + AppConfig.BranchList);
  }

  getUserBranch(UsRefNo) {
    return this.http.get<UsBrDtl[]>(environment.HOST_URL + AppConfig.BranchList.concat('/?id=').concat(UsRefNo));
  }

  getFirmAdd() {
    var url = environment.HOST_URL + AppConfig.GetFirmInfo;
    return this.http.get<FirmDtl>(url);
  }

  getCmpProfile() {
    var url = environment.HOST_URL + AppConfig.GetFirmInfo;
    return this.http.get<FirmDtl>(url);
  }

  saveCmpProfile(params: FirmDtl) {
    var url = environment.HOST_URL + AppConfig.saveFirmInfo;
    return this.http.post<FirmDtl>(url, params);
  }
  saveFrmDocDtl(params: DocDtl) {
    // var url = environment.HOST_URL + AppConfig.FrmDocDtl;
    // return this.http.post<FirmDtl>(url, params);
    var url = environment.HOST_URL + AppConfig.FrmDocDtl;
    return this.http.post<FirmDtl>(url, params);
  }
  GetFrmDoc(docType) {
    var url = environment.HOST_URL + AppConfig.GetFrmDoc + '/?DocType='.concat(docType);
    const httpParam = new HttpParams();
    let parameters: any = {
      params: httpParam
    }
    parameters.responseType = 'text'
    return this.http.get<FirmDtl>(url, parameters);
  }
  DeleteFrmDoc(DocType) {
    return this.http.get(environment.HOST_URL + AppConfig.DeleteFrmDoc + '/?DocType='.concat(DocType));
  }

  saveCmpInfo(params) {
    var url = environment.HOST_URL + AppConfig.saveCmpInfo;
    return this.http.post<CmpInfo>(url, params);
  }

  editCmpInfo(sCmpCode) {
    var url = environment.HOST_URL + AppConfig.editCmpInfo + '/?id='.concat(sCmpCode);
    return this.http.get<CmpInfo>(url);
  }

  getStateList() {
    var url = environment.HOST_URL + AppConfig.getStateList;
    return this.http.get<[]>(url);
  }

  getCmpList() {
    var url = environment.HOST_URL + AppConfig.getCmpList;
    return this.http.get<CmpInfo[]>(url);
  }

  deleteCmp(sCmpCode) {
    return this.http.get(environment.HOST_URL + AppConfig.deleteCmp + '/?id='.concat(sCmpCode));
  }


  getBcList() {
    var url = environment.HOST_URL + AppConfig.getBcList;
    return this.http.get<FirmDtl>(url);
  }

  updateBillCharge(params: BillCharges) {
    return this.http.post<BillCharges>(environment.HOST_URL + AppConfig.updateBillCharge, params);
  }

  updateOptionAc(sObj) {
    return this.http.post<OptionAc>(environment.HOST_URL + AppConfig.updateOptionac, sObj);
  }

  getOptionAc() {
    return this.http.get<OptionAc>(environment.HOST_URL + AppConfig.getOptionac);
  }

  updateOptionEmail(sObj) {
    return this.http.post<OptionEmail>(environment.HOST_URL + AppConfig.updateOptionEmail, sObj);
  }

  getOptionEmail() {
    return this.http.get<OptionEmail>(environment.HOST_URL + AppConfig.getOptionEmail);
  }

  getOptionInv() {
    return this.http.get<OptionEmail>(environment.HOST_URL + AppConfig.getOptionInv);
  }

  updateOptionInv(sObj) {
    return this.http.post<OptionEmail>(environment.HOST_URL + AppConfig.updateOptionInv, sObj);
  }

  ImportStationList(sObj) {
    return this.http.post<StationDtl>(environment.HOST_URL + AppConfig.ImportStationList, sObj);
  }
  ImportVHList(sObj) {
    return this.http.post<VhDtl>(environment.HOST_URL + AppConfig.ImportVHList, sObj);
  }
  ImportMatList(sObj) {
    return this.http.post<MatDtl>(environment.HOST_URL + AppConfig.ImportMatList, sObj);
  }
  ImportDrList(sObj) {
    return this.http.post<DrvDtl>(environment.HOST_URL + AppConfig.ImportDrList, sObj);
  }
  ImportAcmList(sObj) {
    return this.http.post<Acm>(environment.HOST_URL + AppConfig.ImportAcmList, sObj);
  }

  ImportLrList(sObj) {
    return this.http.post<StationDtl>(environment.HOST_URL + AppConfig.ImportLrList, sObj);
  }
}
