export const LrType = [
    { 'name': 'Tobe Billed', value: 'B' },
    { 'name': 'ToPay', value: 'T' },
    { 'name': 'Paid', value: 'P' },
    { 'name': 'FOC', value: 'F' }
];

export const LrGstBy = [
    { 'name': 'None', value: 'NN' },
    { 'name': 'Consignor', value: 'Cr' },
    { 'name': 'Consignee', value: 'Cn' },
    { 'name': 'Billing Party', value: 'Bp' },
    { 'name': 'Transporter', value: 'Tr' }
];

export const LrDelType = [
    { 'name': 'Door Delivery CC Att.', value: 'DD-CC' },
    { 'name': 'Door Delivery Ag CC', value: 'DD-AG' },
    { 'name': 'Godown Delivery', value: 'GD' }
];

export const LrPkgType = [
    { 'name': 'Box', value: 'Box' },
    { 'Drum': 'Drum', value: 'Drum' }
];

export const LrFrAmtCal = [
    { 'name': 'Rate x Weight', value: 'RW' },
    { 'name': 'Rate x Weight x KM', value: 'RWK' },
    { 'name': 'Rate x Qty', value: 'RQ' },
    { 'name': 'Rate x 1', value: 'R1' },
    { 'name': 'None', value: 'NN' }
];