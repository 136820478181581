export class VouDtl {
    constructor(
        public VrBrName: string = '',
        public VrPRefNo: number = 0,
        public VrEUnkCode: string = '',
        public VrEFrom: string = '',
        public VrNo: number = 0,
        public VrExtn: string = '',
        public VrDt?: Date,
        public VrTpName: string = '',
        public VrDAcRefNo: number = 0,
        public VrDAcName: string = '',
        public VrRAcRefNo: number = 0,
        public VrRAcName: string = '',
        public VrAmt: number = 0,
        public VrQty: number = 0,
        public VrRate: number = 0,
        public VrCrDr: string = '',
        public VrChqNo: string = '',
        public VrChqBk: string = '',
        public VrPtBlNo: string = '',
        public VrNarr: string = '',
        public VrVhNo: string = '',
        //public VrItem ?: VouItem[]
    ) { }
}

export class VouItem {
    constructor(
        public VrItPRefNo: number = 0,
        public VrItEUnkCode: string = '',
        public VrItDAcRefNo: number = 0,
        public VrItDAcName: string = '',
        public VrItQty: number = 0,
        public VrItRate: number = 0,
        public VrItAmt: number = 0,
        public VrItCrDr: string = '',
        public VrItEFrom: string = '',
        public VrItTpName: string = '',
        public VrChrgRefNo: number = 0,
        public VrItVhNo: string = '',
        public VrItName: string = ''
    ) { }
}

export class VouFilter {
    constructor(
        public GrpFlag: string = '',
        public MenuFlag: string = '',
        public NoFrom: Number = 0,
        public NoTo: Number = 0,
        public DtFrom?: Date,
        public DtTo?: Date,
        public DtFromYMD: string = '',
        public DtToYMD: string = '',
        public VrBrName: string = '',
        public VrMonth: string = '',
        public VrTpName: string = '',
        public VrDAcName: string = '',
        public VrDAcRefNo: number = 0,
        public VrEFrom: string = '',
        public AcType: string = '',
        public EntryFlag: string = ''
    ) { }
}

export class TrBal {
    constructor(
        public AcType: string = '',
        public AcName: string = '',
        public VrAmt: number = 0,
        public VrDrAmt: number = 0,
        public VrCrAmt: number = 0
    ) { }
}

export class AgPndBl {
    constructor(
        public AbPRefNo: number = 0,
        public InvBpRefNo: number = 0,
        public InvPUnkCode: string = '',
        public InvBrName: string = '',
        public InvType: string = '',
        public InvNo: number = 0,
        public InvExtn: string = '',
        public InvDt?: Date,
        public InvNetAmt: number = 0,
        public InvNetFrAmt: number = 0,
        public InvNetBalAmt: number = 0,
        public InvDrAmt: number = 0,
        public InvCrAmt: number = 0
    ) { }
}

