import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AppConfig } from '../app.config';
import { LrAcm } from '../lr-mod/LrModel';
import { AddressDtl, EInvFilter, EInvIRNDtl, TradInvDtl, TradInvDtlPrint, TradInvItem } from './trad-invoice-model';

@Injectable({
  providedIn: 'root'
})
export class TradInvoiceService {

  constructor(private http: HttpClient) { }

  getBranch() {
    return this.http.get<[]>(environment.HOST_URL + AppConfig.BranchList);
  }
  getAcName() {
    return this.http.get<LrAcm[]>(environment.HOST_URL + AppConfig.getAcmListForInv);
    //GetAcmListForCombo
  }

  getAddressDtl() {
    return this.http.get<AddressDtl[]>(environment.HOST_URL + AppConfig.getAddressList);
  }


  getAcmListForCombo(sType) {
    return this.http.get<[any]>(environment.HOST_URL + AppConfig.getAcmListForCombo.concat('/?id=').concat(sType));
  }


  getTradInvoiceByCode(sId) {
    return this.http.get<TradInvDtl>(environment.HOST_URL + AppConfig.getTradInvoiceByCode.concat('/?id=').concat(sId));
  }

  getTradItemByInvCode(sObj) {
    return this.http.post<TradInvItem[]>(environment.HOST_URL + AppConfig.getTradInvoiceByCode, sObj);
  }

  getTradInvSummary(sObj, isExportToExcel: boolean = false) {
    var sUrl = environment.HOST_URL + AppConfig.getTradInvSummary;

    const httpParam = new HttpParams();
    let parameters: any = {
      params: httpParam
    }
    if (isExportToExcel) {
      parameters.responseType = 'blob' as 'json'
    }
    return this.http.post<[any]>(sUrl, sObj, parameters);

  }

  saveTradInvoice(sObj: TradInvDtlPrint) {
    const httpParam = new HttpParams().append('branch', localStorage.getItem('tpsBranch'));
    return this.http.post<TradInvDtl>(environment.HOST_URL + AppConfig.saveTradInvoice, sObj, { params: httpParam });
  }

  updateTradInvoice(params: TradInvDtlPrint) {
    return this.http.post<TradInvDtl>(environment.HOST_URL + AppConfig.updateTradInvoice, params);
  }

  eInvTradInvoice(params: EInvFilter) {
    return this.http.post<TradInvDtl>(environment.HOST_URL + AppConfig.eInvTradInvoice, params);
  }

  updateIrnTradInvoice(params: EInvIRNDtl) {
    return this.http.post<TradInvDtl>(environment.HOST_URL + AppConfig.updateIrnTradInvoice, params);
  }


  deleteTradInvoice(sUnPUnkCode) {
    return this.http.get(environment.HOST_URL + AppConfig.deleteTradInvoice + "/?id=".concat(sUnPUnkCode));
  }

  getInvTcList() {
    return this.http.get<[]>(environment.HOST_URL + AppConfig.getInvTcList);
  }

  pdfTradInvoice(sObj) {
    var url = environment.HOST_URL + AppConfig.pdfTradInvoice;
    const httpParam = new HttpParams();
    let parameters: any = {
      params: httpParam,
    }
    parameters.responseType = 'application/json';
    // const httpOptions = {
    //   responseType: 'blob' as 'json'
    // };
    //return this.http.get(url, parameters);

    return this.http.post<[any]>(url, sObj, parameters);

  }

}
