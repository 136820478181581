import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AppConfig } from '../app.config';
import { ItemDtl } from '../vh-parts/vhparts.Model';

@Injectable({
  providedIn: 'root'
})
export class VhPartsService {

  constructor(private http: HttpClient) { }

  getPartList() {
    return this.http.get<ItemDtl[]>(environment.HOST_URL + AppConfig.getPartList);
  }

  getPartById(sItPRefNo) {
    return this.http.get(environment.HOST_URL + AppConfig.getPartById + '/?id='.concat(sItPRefNo));
  }

  addPart(sObj) {
    return this.http.post<ItemDtl>(environment.HOST_URL + AppConfig.addPart, sObj);
  }

  updatePart(sObj) {
    return this.http.post<ItemDtl>(environment.HOST_URL + AppConfig.updatePart, sObj);
  }
  deletePart(sItPRefNo) {
    return this.http.get(environment.HOST_URL + AppConfig.deletePart + '/?id='.concat(sItPRefNo));
  }

  getPartCatById(eItCatPRefNo) {
    return this.http.get(environment.HOST_URL + AppConfig.getPartCatById + '/?id='.concat(eItCatPRefNo));
  }
  getPartCatList() {
    return this.http.get(environment.HOST_URL + AppConfig.getPartCatList);
  }
  addPartCat(sObj) {
    return this.http.post(environment.HOST_URL + AppConfig.addPartCat, sObj);
  }
  updatePartCat(sObj) {
    return this.http.post(environment.HOST_URL + AppConfig.updatePartCat, sObj);
  }
  deletePartCat(eItCatPRefNo) {
    return this.http.get(environment.HOST_URL + AppConfig.deletePartCat + '/?id='.concat(eItCatPRefNo));
  }

  getVhExpGroup() {
    return this.http.get(environment.HOST_URL + AppConfig.getVhExpGroup);
  }

  getMakeList() {
    return this.http.get(environment.HOST_URL + AppConfig.getMakeList);
  }
  getVmMakeById(eVmMkPRefNo) {
    return this.http.get(environment.HOST_URL + AppConfig.getVmMakeById + '/?id='.concat(eVmMkPRefNo));
  }

  addVmMake(sObj) {
    return this.http.post(environment.HOST_URL + AppConfig.addVmMake, sObj);
  }
  updateVmMake(sObj) {
    return this.http.post(environment.HOST_URL + AppConfig.updateVmMake, sObj);
  }
  deleteVmMake(eVmMkPRefNo) {
    return this.http.get(environment.HOST_URL + AppConfig.deleteVmMake + '/?id='.concat(eVmMkPRefNo));
  }

  getItemGradeList() {
    return this.http.get(environment.HOST_URL + AppConfig.getItemGradeList);
  }
  getItemGradeById(eRefNo) {
    return this.http.get(environment.HOST_URL + AppConfig.getItemGradeById + '/?id='.concat(eRefNo));
  }
  addItemGrade(sObj) {
    return this.http.post(environment.HOST_URL + AppConfig.addItemGrade, sObj);
  }
  updateItemGrade(sObj) {
    return this.http.post(environment.HOST_URL + AppConfig.updateItemGrade, sObj);
  }
  deleteItemGrade(sRefNo) {
    return this.http.get(environment.HOST_URL + AppConfig.deleteItemGrade + '/?id='.concat(sRefNo));
  }





  getVmPurPartyList() {
    return this.http.get(environment.HOST_URL + AppConfig.getVmPurPartyList);
  }
  getVmPurPartyById(ePtPRefNo) {
    return this.http.get(environment.HOST_URL + AppConfig.getVmPurPartyById + '/?id='.concat(ePtPRefNo));
  }
  addVmPurParty(sObj) {
    return this.http.post(environment.HOST_URL + AppConfig.saveVmPurchase, sObj);
  }
  updateVmPurParty(sObj) {
    return this.http.post(environment.HOST_URL + AppConfig.updateVmPurParty, sObj);
  }
  deleteVmPurParty(ePtPRefNo) {
    return this.http.get(environment.HOST_URL + AppConfig.deleteVmPurParty + '/?id='.concat(ePtPRefNo));
  }


}

